var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('scrollable-container',{class:_vm.$style.scrollableContainer},[_c('div',{class:_vm.$style.buttonContainer},[_c('button',{class:_vm.$style.button,on:{"click":function($event){return _vm.openModal('addPrice')}}},[_c('div',{class:_vm.$style.plus},[_vm._v(" + ")]),_vm._v(" Добавить ")])]),_c('container',{class:_vm.$style.scrollableContent},[_c('table',{class:_vm.$style.table},[_c('thead',{class:_vm.$style.header},[_vm._l((_vm.columns.slice(0, -1)),function(column){
var _obj;
return _c('td',{key:column.field,style:({ width: column.width }),on:{"click":function($event){return _vm.changeSort(column)}}},[_c('div',{class:_vm.$style.tdInline},[_vm._v(" "+_vm._s(column.name)+" ")]),_c('img',{class:[
              _vm.$style.tdInline,
              _vm.$style.arrow,
              ( _obj = {}, _obj[_vm.$style.asc] = column.sort == 2, _obj[_vm.$style.hidden] = column.sort == 0, _obj ) ],attrs:{"src":require("@/assets/images/icons/table/sort-arrow.svg")}})])}),_c('td',{style:({ width: _vm.columns[_vm.columns.length - 1].width })},[_vm._v(" "+_vm._s(_vm.columns[_vm.columns.length - 1].name)+" ")])],2),_c('tbody',_vm._l((_vm.sortedPrinters),function(price){return _c('tr',{key:price.id,class:_vm.$style.item},[_c('td',[_vm._v(" "+_vm._s(price.name)+" ")]),_vm._l((_vm.columns.slice(1, -1)),function(column){
            var _obj;
return _c('td',{key:price.id + column.field},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(price[column.field]),expression:"price[column.field]"}],class:[
                _vm.$style.input,
                ( _obj = {}, _obj[_vm.$style.inactive] = _vm.readOnly, _obj ) ],attrs:{"type":"text","readonly":_vm.readOnly},domProps:{"value":(price[column.field])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(price, column.field, $event.target.value)}}})])}),_c('td',[_c('div',{class:_vm.$style.flex},[(_vm.readOnly)?_c('img',{class:_vm.$style.icon,attrs:{"title":"Редактировать","src":require("@/assets/images/icons/navigation/tasks.svg")},on:{"click":function($event){_vm.readOnly = false}}}):_vm._e(),(!_vm.readOnly)?_c('img',{class:[_vm.$style.saveIcon, _vm.$style.icon],attrs:{"title":"Сохранить","src":require("@/assets/images/icons/navigation/confirm.svg")},on:{"click":function($event){return _vm.save(price)}}}):_vm._e(),(_vm.readOnly && price.id !== 1)?_c('img',{class:[_vm.$style.deleteIcon, _vm.$style.icon],attrs:{"title":"Удалить","src":require("@/assets/images/icons/navigation/trash.svg")},on:{"click":function($event){return _vm.deleteItem(price)}}}):_vm._e(),(!_vm.readOnly)?_c('img',{class:[_vm.$style.deleteIcon, _vm.$style.saveIcon, _vm.$style.icon],attrs:{"title":"Отменить","src":require("@/assets/images/icons/navigation/cancel.svg")},on:{"click":_vm.cancel}}):_vm._e()])])],2)}),0)])]),_c('delete-price',{class:_vm.$style.modal,attrs:{"id":"deletePrice","selected":_vm.selectedPrice},on:{"close":function($event){return _vm.closeModal('deletePrice')},"update":function($event){return _vm.getList()}}}),_c('add-price',{class:_vm.$style.modal,attrs:{"id":"addPrice"},on:{"close":function($event){return _vm.closeModal('addPrice')},"update":function($event){return _vm.getList()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }