<template>
  <modal
    header-text="Добавление новой записи"
    button-text="Добавить"
    :class="$style.modal"
    @action="addPrice()"
    @close="$emit('close')"
  >
    <div :class="$style.inputContainer">
      <label :class="$style.label">
        <div>Введите имя принтера</div>
        <div>(как он объявлен в системе)</div>
      </label>
      <input v-model="printer" type="text" :class="$style.input">
    </div>
  </modal>
</template>

<script>
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import { addPrice } from '@/api/methods/printPrice';
import Modal from '@/components/common/modal/printPrice-modal.vue';

import 'vue-toast-notification/dist/theme-default.css';

Vue.use(VueToast);

export default {
  components: { Modal },
  data: () => ({
    printer: '',
  }),
  methods: {
    close() {
      this.$emit('close');
    },
    async addPrice() {
      if (this.printer.trim().length > 0) {
        const result = await addPrice(this.printer.trim());
        if (result === 'success') this.printer = '';
        this.close();
        this.$emit('update');
      } else {
        Vue.$toast.open({
          message: 'Введите имя принтера',
          type: 'error',
        });
      }
    },
  },
};
</script>

<style lang="scss" module>
.input {
  margin: 5px;
  width: 60%;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
}
.inputContainer {
  font-size: 13px;
  margin: 5px 30px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.label {
  width: 40%;
  line-height: normal;
}
.alert {
  padding: 5px;
  color: $red;
}
</style>
