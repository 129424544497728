<template>
  <scrollable-container :class="$style.scrollableContainer">
    <div :class="$style.buttonContainer">
      <button :class="$style.button" @click="openModal('addPrice')">
        <div :class="$style.plus">
          +
        </div>
        Добавить
      </button>
    </div>
    <container :class="$style.scrollableContent">
      <table :class="$style.table">
        <thead :class="$style.header">
          <td
            v-for="column in columns.slice(0, -1)"
            :key="column.field"
            :style="{ width: column.width }"
            @click="changeSort(column)"
          >
            <div :class="$style.tdInline">
              {{ column.name }}
            </div>
            <img
              src="@/assets/images/icons/table/sort-arrow.svg"
              :class="[
                $style.tdInline,
                $style.arrow,
                {
                  [$style.asc]: column.sort == 2,
                  [$style.hidden]: column.sort == 0,
                },
              ]"
            >
          </td>
          <td :style="{ width: columns[columns.length - 1].width }">
            {{ columns[columns.length - 1].name }}
          </td>
        </thead>
        <tbody>
          <tr
            v-for="price in sortedPrinters"
            :key="price.id"
            :class="$style.item"
          >
            <td>
              {{ price.name }}
            </td>
            <td
              v-for="column in columns.slice(1, -1)"
              :key="price.id + column.field"
            >
              <input
                v-model="price[column.field]"
                type="text"
                :readonly="readOnly"
                :class="[
                  $style.input,
                  {
                    [$style.inactive]: readOnly,
                  },
                ]"
              >
            </td>
            <td>
              <div :class="$style.flex">
                <img
                  v-if="readOnly"
                  title="Редактировать"
                  src="@/assets/images/icons/navigation/tasks.svg"
                  :class="$style.icon"
                  @click="readOnly = false"
                >
                <img
                  v-if="!readOnly"
                  title="Сохранить"
                  src="@/assets/images/icons/navigation/confirm.svg"
                  :class="[$style.saveIcon, $style.icon]"
                  @click="save(price)"
                >
                <img
                  v-if="readOnly && price.id !== 1"
                  title="Удалить"
                  src="@/assets/images/icons/navigation/trash.svg"
                  :class="[$style.deleteIcon, $style.icon]"
                  @click="deleteItem(price)"
                >
                <img
                  v-if="!readOnly"
                  title="Отменить"
                  src="@/assets/images/icons/navigation/cancel.svg"
                  :class="[$style.deleteIcon, $style.saveIcon, $style.icon]"
                  @click="cancel"
                >
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </container>
    <delete-price
      id="deletePrice"
      :class="$style.modal"
      :selected="selectedPrice"
      @close="closeModal('deletePrice')"
      @update="getList()"
    />
    <add-price
      id="addPrice"
      :class="$style.modal"
      @close="closeModal('addPrice')"
      @update="getList()"
    />
  </scrollable-container>
</template>

<script>
import { getPrintPrices, editPrice } from '@/api/methods/printPrice';
import ScrollableContainer from '@/components/common/blocks/scrollable-container.vue';
import Container from '@/components/common/blocks/container.vue';
import DeletePrice from '@/views/settings/printPrice/delete-price.vue';
import AddPrice from '@/views/settings/printPrice/add-price.vue';

export default {
  components: {
    ScrollableContainer,
    Container,
    DeletePrice,
    AddPrice,
  },
  data: () => ({
    prices: [],
    columns: [
      { name: 'Принтер', field: 'name', width: '50%', sort: 1 },
      { name: 'Стоимость', field: 'price', width: '15%', sort: 0 },
      { name: 'Комментарий', field: 'comment', width: '25%', sort: 0 },
      { name: 'Действия', field: '', width: '10%', sort: 0 },
    ],
    readOnly: true,
    selectedPrice: { name: '' },
  }),
  computed: {
    sortedPrinters() {
      const calc = this.prices.slice(0).sort(this.compare);
      return calc;
    },
  },
  async created() {
    await this.getList();
  },
  async mounted() {
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/refresh') {
          await this.getList();
        }
      },
    });
  },
  methods: {
    compare(a, b) {
      const index = this.columns.findIndex((x) => x.sort > 0);
      const key = this.columns[index].field;
      let a1 = a;
      let b1 = b;

      if (key !== 'price') {
        a1 = a1[key].toLowerCase();
        b1 = b1[key].toLowerCase();
      } else {
        a1 = a[key];
        b1 = b[key];
      }

      if (this.columns[index].sort === 1) {
        if (a1 > b1) {
          return -1;
        }

        if (a1 < b1) {
          return 1;
        }
        return 0;
      }

      if (this.columns[index].sort === 2) {
        if (a1 < b1) {
          return -1;
        }

        if (a1 > b1) {
          return 1;
        }

        return 0;
      }
      return 0;
    },
    changeSort(column) {
      const { sort } = column;
      this.columns.forEach((col) => {
        col.sort = 0;
      });
      if (sort === 1) {
        column.sort = 2;
      } else column.sort = 1;
    },
    closeModal(id) {
      document.getElementById(id).style.display = 'none';
    },
    openModal(id) {
      document.getElementById(id).style.display = 'flex';
    },
    async deleteItem(price) {
      this.selectedPrice = price;
      this.openModal('deletePrice');
    },
    async save(item) {
      await editPrice(item);
      this.readOnly = true;
    },
    async cancel() {
      await this.getList();
      this.readOnly = true;
    },
    async getList() {
      this.prices = [];
      this.promise = await getPrintPrices().then(({ data }) => {
        if (data) {
          this.prices = data.result;
        }
      });
    },
  },
};
</script>

<style lang="scss" module>
.saveIcon {
  width: 20px;
}

.deleteIcon {
  margin-left: 10px;
}

.table {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  border-collapse: collapse;
  font-weight: 400;
  word-break: break-word;
}

.header {
  position: sticky;
  z-index: 3;
  top: 0;
  font-weight: 500;
  background: #dbf1ff;
  height: 31px;
  td {
    border: 1px solid $borderColor;
    align-items: center;
    color: #232326;
    letter-spacing: -0.08px;
    font-size: 13px;
    line-height: 18px;
    padding: 6px 4px 4px 20px;
    cursor: pointer;
  }
}

.item {
  height: 29px;
  td {
    border: 1px solid $borderColor;
    align-items: center;
    letter-spacing: -0.24px;
    color: $black;
    font-size: 14px;
    line-height: 14px;
    padding: 7px 4px 7px 20px;
    &.name {
      font-weight: 500;
      padding-left: 37px;
    }
  }
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $branchColor;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

.scrollableContent {
  margin: 0 8px 0 0;
  padding: 0 12px 0 20px;
}

.scrollableContainer {
  padding-bottom: 20px;
}

.flex {
  display: flex;
}

.inactive {
  border: 0px;
  background: transparent;
}
.inactive:focus {
  outline: none !important;
}

.icon {
  cursor: pointer;
}

.modal {
  display: none;
  z-index: 4;
}

.button {
  border: 1px solid white;
  cursor: pointer;
  color: white;
  height: 30px;
  width: 127px;
  background: #2985bf;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plus {
  font-weight: 500;
  font-size: 24px;
  padding-right: 7px;
}

.buttonContainer {
  margin: 10px 20px;
}

.tdInline {
  display: inline-flex;
}

.arrow {
  margin: 7px 5px 0px 5px;

  &.asc {
    transform: rotate(180deg);
  }
  &.hidden {
    visibility: hidden;
  }
}

.input {
  width: 100%;
}
</style>
