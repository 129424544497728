import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import axios from '@/api/axios';
import 'vue-toast-notification/dist/theme-default.css';

Vue.use(VueToast);

function openToast(message, type) {
  Vue.$toast.open({
    message,
    type,
  });
}

function getSettings(link) {
  return axios.post(link).then((response) => {
    if (response.data.success) { return response; }

    openToast(response.data.message, 'error');
    response.data = null;

    return response;
  });
}

export function getPrintPrices() {
  return getSettings('/printPrice/list');
}

export function deletePrice(id) {
  return axios.post(`/printPrice/deletePrice/?id=${id}`).then((response) => {
    if (response.data.success) {
      openToast('Стоимость печати успешно удалена', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function editPrice(price) {
  return axios.post('/printPrice/editPrice', { price }).then((response) => {
    if (response.data.success) {
      openToast('Изменения успешно сохранены', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function addPrice(name) {
  return axios.post('/printPrice/addPrice', { name }).then((response) => {
    if (response.data.success) {
      openToast('Стоимость печати успешно добавлена', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}
