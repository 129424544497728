<template>
  <modal
    header-text=""
    button-text="Удалить"
    @action="delPrice"
    @close="$emit('close')"
  >
    <div :class="$style.inputContainer">
      Вы уверены, что хотите удалить стоимость печати для принтера
      {{ selected.name }}?
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/common/modal/printPrice-modal.vue';
import { deletePrice } from '@/api/methods/printPrice';

export default {
  components: { Modal },
  props: ['selected'],
  methods: {
    close() {
      this.$emit('close');
    },
    async delPrice() {
      if (this.selected.id) {
        await deletePrice(this.selected.id);
        this.close();
        this.$emit('update');
      }
    },
  },
};
</script>

<style lang="scss" module>
.input {
  margin: 5px;
}
.inputContainer {
  font-size: 13px;
  margin: 5px;
}
</style>
